import React, { Component } from 'react';
//import { Link } from 'react-router-dom';

//import minorasLogo_O from './assets/minoras-logo-1500_O.png';
import tursabImg from './assets/tursab-dvs-11900.png';
import facebook from './assets/s_facebook.png';
import instagram from './assets/s_instagram.png';
import linkedin from './assets/s_linkedin.png';
//import discord from './assets/s_discord.png';
//import twitter from './assets/s_twitter.png';

const lList = [
    {
        id: "EN",
        text1: "All information and images on this site are obtained from reliable professional sources specialized in their field. In accordance with the privacy and data protection policy, any copying and distribution of information from this website is not allowed.",
        text2: "EXPLORE",
        text3: "About"
    },
    {
        id: "DE",
        text1: "Alle Informationen und Bilder auf dieser Site stammen aus zuverlässigen professionellen Quellen, die auf ihrem Gebiet spezialisiert sind. Gemäß den Datenschutzbestimmungen ist jegliches Kopieren und Verbreiten von Informationen dieser Website nicht gestattet.",
        text2: "ENTDECKEN",
        text3: "Über uns"
    },
    {
        id: "FR",
        text1: "Toutes les informations et images présentes sur ce site sont obtenues auprès de sources professionnelles fiables et spécialisées dans leur domaine. Conformément à la politique de confidentialité et de protection des données, toute copie et distribution d'informations à partir de ce site Web n'est pas autorisée.",
        text2: "EXPLORE",
        text3: "About"
    },
    {
        id: "ES",
        text1: "Toda la información e imágenes de este sitio se obtienen de fuentes profesionales confiables especializadas en su campo. De acuerdo con la política de privacidad y protección de datos, no se permite la copia y distribución de información de este sitio web.",
        text2: "EXPLORE",
        text3: "About"
    },
    {
        id: "TR",
        text1: "Bu sitedeki tüm bilgiler ve resimler, konusunda uzmanlaşmış güvenilir profesyonel kaynaklardan elde edilmektedir. Gizlilik ve veri koruma politikası uyarınca bu web sitesinden herhangi bir bilgi kopyalanması ve dağıtılmasına izin verilmez.",
        text2: "KEŞFET",
        text3: "Hakkında"
    }
];

class Footer extends Component {
    handleClick = (pg) => {
        let url = '';
        switch(pg) {
            case 'facebook':
                url = 'https://fb.me/minorastravel';
                break;
            case 'instagram':
                url = 'https://www.instagram.com/minorastravel';
                break;
            case 'linkedin':
                url = 'https://www.linkedin.com/company/minorastravel/';
                break;
            /*
            case 'twitter':
                url = 'https://twitter.com/derzane.medya/';
                break;
            case 'discord':
                url = 'https://discord.gg/mRgU4HtNJg';
                break;
            case 'freepik':
                url = 'https://www.freepik.com/photos/school';
                break;
            */
            case 'tursab':
                url = 'https://www.tursab.org.tr/tr/ddsv';
                break;
            default:
                return (0);
        }
        const win = window.open(url, '_blank');
        if (win != null) {
              win.focus();
        }
        return (1);
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div className="Footer">
                {/* 
                <div className="FooterLogo">
                    <img src={minorasLogo_O} height="100px" alt="Minoras"/>
                </div>
                */}
                <div className="FooterClaim">
                    <div className="MenuAcenta">
                        Minoras Turizm Seyahat Acentası - 11900
                    </div>
                    {lItem.text1}
                </div>
                <div className="FooterCenter">
                    <img className="FooterLink" src={tursabImg} alt="Tursab" onClick={() => this.handleClick('tursab')} />
                    {/*
                    <div className="FooterCenterHeader">
                        {lItem.text2}
                    </div>
                    <div>
                        <Link className="FooterCenterLink" to="/hakkimizda">{lItem.text3}</Link>
                    </div>
                    */}
                </div>
                <div className="FooterRight">
                    <div className="FooterLinks">
                        <img className="FooterLink" src={facebook} alt="Facebook" height='50' onClick={() => this.handleClick('facebook')} />
                        <img className="FooterLink" src={instagram} alt="Instagram" height='50' onClick={() => this.handleClick('instagram')} />
                        <img className="FooterLink" src={linkedin} alt="LinedIn" height='50' onClick={() => this.handleClick('linkedin')} />
                        {/*
                        <img className="FooterLink" src={discord} alt="LinkeIn" height='50' onClick={() => this.handleClick('discord')} />
                        <img className="FooterLink" src={twitter} alt="Twitter" height='50' onClick={() => this.handleClick('twitter')} />
                        */}
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;