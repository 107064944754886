import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';

import minorasLogo_O from './assets/minoras-logo-1500_O.png';
import menuImg from './assets/menubar.png';

const lList = [
    {
        id: "EN",
        language: "English",
        text1: "Home",
        text2: "About",
        text3: "Contact",
        text4: "Tailor-made Destination Experience"
    },
    {
        id: "DE",
        language: "Deutsch",
        text1: "Startseite",
        text2: "Über uns",
        text3: "Kontakt",
        text4: "Maßgeschneiderte Reise-Erlebnisse"
    },
    {
        id: "FR",
        language: "Français",
        text1: "Page d'Accueil",
        text2: "Sur",
        text3: "Contact",
        text4: "Expérience de destination sur mesure"
    },
    {
        id: "ES",
        language: "Español",
        text1: "Pagina de inicio",
        text2: "Sobre Nosotros",
        text3: "Contacto",
        text4: "Experiencia de destino a medida"
    },
    {
        id: "TR",
        language: "Türkçe",
        text1: "Ana Sayfa",
        text2: "Hakkında",
        text3: "İletişim",
        text4: "Kişiye Özel Destinasyon Deneyimi"
    }
];

class Menu extends Component {
    state = {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
        smallMenuLimit: 768
    }

    getMenuSize = () => {
        this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
    }

    componentDidMount() {
        window.addEventListener('resize', this.getMenuSize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getMenuSize);
    }

    changeLanguage = (language) => {
        const text = `Menu: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, Language changed from ${this.props.language} to ${language}`;
        this.props.invokeLogger({ id: "w", text });
        localStorage.setItem("userLanguage", language);

        if (this.props.changeLanguage)
            this.props.changeLanguage(language);
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div className="Menu">
                
                <Link to='/'>
                    <div className="MenuLogo">
                        <img src={minorasLogo_O} width="48px" alt='minoras.com' />
                    </div>
                </Link>
                
                {/*}
                <div className="MenuContainer">
                    {
                        this.state.innerWidth >= this.state.smallMenuLimit ?
                        <div>
                            <Link className="MenuItem" to='/hakkimizda'>{lItem.text1}</Link>
                        </div> : null
                    }
                    {
                        this.state.innerWidth >= this.state.smallMenuLimit ?
                        <div>
                            <Link className="MenuItem" to='/iletisim'>{lItem.text2}</Link>
                        </div> : null
                    }
                    {
                        this.state.innerWidth >= this.state.smallMenuLimit ?
                        <div className="Language">
                            <div>{lItem.text3}</div>
                            <div className="LanguageContent">
                            {
                                lList.map((item,i) => (
                                    <div
                                        className="LanguageItem"
                                        key={item.id}
                                        onClick={() => this.changeLanguage(item.id)}
                                    >
                                        {item.language}
                                    </div>
                                ))
                            }
                            </div>  
                        </div> : null
                    }
                </div>
                */}
                <div></div>
                <div className="MenuRightContainer">
                    <div className="MenuShort">
                        <img className="MenuIcon" src={menuImg} alt="Minoras" />
                        <div className="MenuShortContent">
                            <div className="MenuShortItem"><Link className="MenuSubItemItem" to="/anasayfa/">{lItem.text1}</Link></div>
                            {/*<div className="MenuShortItem"><Link className="MenuSubItemItem" to="/hakkimizda/">{lItem.text2}</Link></div>*/}
                            <div className="MenuShortItem"><Link className="MenuSubItemItem" to="/iletisim/">{lItem.text3}</Link></div>
                            <hr />
                            {
                                lList.map((item,i) => (
                                    <div className="MenuShortItem" key={i}>
                                        <div
                                            className="MenuSubItemItem"
                                            onClick={() => this.changeLanguage(item.id)}
                                        >
                                            {item.language}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                {/*
                <div className="MenuSlogan">
                    {lItem.text4}
                </div>
                */}
                <div className="MenuAcenta">
                    Minoras Turizm Seyahat Acentası - 11900
                </div>
            </div>

        )        
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Menu);